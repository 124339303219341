<template>
    <div>
        <header-portrate />


    <div class="m-2">
        <bread-crumbs :items="items"></bread-crumbs> 
        <tabs-comp :tabs="tabs" />
        <div style="margin:auto;width:75%">
        <v-row class="m-3">
            <v-col cols="12" md="3" sm="12">
                <v-text-field
                    :label="lang.shortcust_name + `*`"
                    v-model="settings.site_title"
                    >{{ settings.site_title }}</v-text-field>
            </v-col>
            <v-col cols="12" md="3" sm="12">
                <v-text-field
                    :label="lang.company_name+ ` *`"
                    v-model="settings.company_name"
                    >{{ settings.company_name }}</v-text-field>
            </v-col>
            <v-col cols="12" md="3" sm="12">
                <v-text-field
                    :label="lang.company_name_en+ ` *`"
                    v-model="settings.company_name_en"
                    >{{ settings.company_name_en }}</v-text-field>
            </v-col>
            <v-col cols="12" md="3" sm="12">
                <v-text-field
                    :label="lang.crt_number+ ` *`"
                    v-model="settings.crt_number"
                    >{{ settings.crt_number }}</v-text-field>
            </v-col>
        </v-row>
        <v-row class="m-3">
            <v-col cols="12" md="2" sm="12">
                <v-text-field
                    :label="this.lang.vat_number+ ` *`"
                    v-model="settings.vat_number"
                    >{{ settings.vat_number }}</v-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="12">
                <v-text-field
                    :label="this.lang.contact_number+ ` *`"
                    v-model="settings.mobile"
                    >{{ settings.mobile }}</v-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="12">
                <v-text-field
                    label="العنوان بالعربي *"
                    v-model="settings.address"
                    >{{ settings.address }}</v-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="12">
                <v-text-field
                    label="English Adress *"
                    v-model="settings.address_en"
                    >{{ settings.address_en }}</v-text-field>
            </v-col>
        </v-row>
        <v-row class="m-3">
            <v-col cols="12" md="12" sm="12" v-if="currentFile">
                <div>
                    <v-progress-linear
                    v-model="progress"
                    color="light-blue"
                    height="25"
                    reactive
                    >
                    <strong>{{ progress }} %</strong>
                    </v-progress-linear>
                </div>
            </v-col>
            <v-col cols="12" md="12" sm="12">
                <v-file-input
                    show-size
                    label="File input"
                    ></v-file-input>
            </v-col>
        </v-row>
        <v-row class="m-3">
            <v-col cols="12" md="6" sm="12">
                <v-textarea
                    label="شروط الاستخدام"
                    v-model="settings.terms"
                    >{{ settings.terms }}</v-textarea>
            </v-col>
            
            <v-col cols="12" md="6" sm="12">
                <v-textarea
                    label="التفويض"
                    v-model="settings.tafweed"
                    >{{ settings.tafweed }}</v-textarea>
            </v-col>
        </v-row>
        <div>
            <small style="color:red">* في حال تغيير الرقم الضريبي سيتوجب عليك إعادة تفعيل الترخيص</small>
        </div>
         <v-btn style="background:green;color:#FFF;margin-bottom:30px;" @click="updateSettings()">{{lang.save}}</v-btn>
        </div>
        
        <vue-snotify></vue-snotify>
    </div>
        <Footer />
    </div>
</template>

<script>
import axios from 'axios'
import BreadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import UploadService from "../UploadFilesService";
export default{
    components: {BreadCrumbs, TabsComp, HeaderPortrate, Footer},
    data() {
        return {
            currentFile: undefined,
            progress: 0,
            active_tab:0,
            fileInfos: [],
            message: "",
            settings:{
                site_title: '',
                company_name: '',
                company_name_en: '',
                vat_number: '',
                logo: '',
                address: '',
                tafweed: '',
                terms: '',
                crt_number: '',
                mobile: '',
                address_en: '',
            },
            
        }
    },
    methods: {
        checkSize(file){
            this.progress = 0;
            this.currentFile = file;

        },
        upload() {
            if (!this.currentFile) {
                this.message = "Please select a file!";
                return;
            }

            this.message = "";

            UploadService.upload(this.currentFile, (event) => {
                this.progress = Math.round((100 * event.loaded) / event.total);
            })
                .then((response) => {
                this.message = response.data.message;
                return UploadService.getFiles();
                })
                .then((files) => {
                this.fileInfos = files.data;
                })
                .catch(() => {
                this.progress = 0;
                this.message = "Could not upload the file!";
                this.currentFile = undefined;
                });
            },
        getSettings()
        {
            const post = new FormData();
            post.append("type",'getSettings');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[all]",'all');
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    this.settings = res.results.data;
                }
            )
        },
        updateSettings(){
            const post = new FormData();
            post.append("type",'updateSettings');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[site_title]",this.settings.site_title);
            post.append("data[company_name]",this.settings.company_name);
            post.append("data[vat_number]",this.settings.vat_number);
            post.append("data[logo]",this.settings.logo);
            post.append("data[address]",this.settings.address);
            post.append("data[tafweed]",this.settings.tafweed);
            post.append("data[terms]",this.settings.terms);
            post.append("data[company_name_en]",this.settings.company_name_en);
            post.append("data[address_en]",this.settings.address_en);
            post.append("data[crt_number]",this.settings.crt_number);
            post.append("data[mobile]",this.settings.mobile);

            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    if (this.$snotify.notifications.length > 0) {
                        this.$snotify.notifications.forEach(notification => {
                            this.$snotify.remove(notification.id)
                        });
                    }
                    this.settings = res.results.data;
                    this.$store.state.settings = res.results.data;
                    this.$snotify.success('تم حفظ الاعدادات ..\nيرجى اخذ العلم انه اذا قمت بتغيير الرقم الضريبي سيتوجب عليك اعادة تفعيل النسخة', {
                        timeout: 5000,
                        showProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
            )
        }
    },
    
    created() {
        this.getSettings();
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items: function() {
            return {
                    text: this.lang.system_settings,
                    disabled: true,
                    to: '/settings/system-settings',
                }
        },
        tabs: function() {
            return [
                {
                    index:0,name:this.lang.system_settings,href:'/settings/system-settings', class: 'mytab'
                },
                {
                    index:1,name:this.lang.users,href:'/settings/users-settings', class: 'mytab2'
                },
                {
                    index:2,name:this.lang.permission,href:'/settings/permissions-settings', class: 'mytab3'
                }
            ]
        }
    },
}
</script>